.home-page {
    width: 100%;
    min-height: 100vh;
    background-color: #ff1a8d;
    background-attachment: fixed;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.clients-page {
    min-height: calc(100vh - 70px);
    padding: 30px 0;
}

.clients-page .container {
    background-color: white;
    border-radius: 30px;
}

.clients-page .container .row {
    padding: 40px;
    gap: 24px;
}

.clients-page .container .box {
    border-color: transparent;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
    border-radius: 1rem;
}

.clients-page .container .box.box-filtros {
    padding: 25px 16px;
    display: flex;
    justify-content: space-between;
}

.clients-page .container .box.box-filtros .filtros {
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.clients-page .container .box.box-filtros .filtros select {
    width: 100%;
    height: 38px;
    min-width: 150px;
    padding: 5px 5px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: white;
    color: black;
    text-align: left;
    overflow: hidden;
}

.clients-page .container .box.box-filtros .filtros select:focus-visible {
    outline-color: #ff1a8d;
}

.clients-page .container .box.box-filtros .filtros select option:hover {
    background-color: #ff1a8d;
    color: white;
}

.clients-page .container .box.box-filtros label {
    color: #ff1a8d;
    font-weight: bold;
}

.clients-page .container .box.box-filtros .buscar {
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.clients-page .container .box.box-filtros .buscar input {
    height: 38px;
    min-width: 150px;
    padding: 5px 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: white;
}

.clients-page .container .box.box-filtros .buscar input:focus-visible {
    outline-color: #ff1a8d;
}

.clients-page .container .box.box-filtros .crear {
    display: flex;
    justify-content: center;
    align-items: center;
}

.clients-page .container .box.box-filtros .crear button {
    height: 38px;
    padding: 0 15px;
    background-color: #ff1a8d;
    border: 1px solid #ff1a8d;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    line-height: 1;
    transition: all 0.5s;
}

.clients-page .container .box.box-filtros .crear button:hover {
    background-color: white;
    border: 1px solid #ff1a8d;
    color: #ff1a8d;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
}

.box-clients-title {
    padding: 16px;
    font-size: 20px;
    font-weight: bold;
    color: #ff1a8d;
}

.box-clients-table {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.box-clients-table .row {
    padding: 0 16px !important;
    gap: 0 !important;
}

.box-clients-table-users {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.box-clients-table-users-gray {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(78,123,221,.10980392156862745);
}

.box-clients-table-title {
    text-align: center;
    color: #ff1a8d;
    border-bottom: 1px solid #ff1a8d;
    border-top: 1px solid #ff1a8d;
    font-weight: bold;
    padding: 12px;
}

.badge-danger {
    color: white;
    background-color: #dc3545;
}

.badge-success {
    color: white;
    background-color: #28a745;
}

.box-clients-table-users-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.box-clients-table-users-button-edit {
    width: 50%;
    padding: 0 5px;
}

.box-clients-table-users-button-edit button {
    width: 100%;
    padding: 5px;
    background-color: darkblue;
    border: 1px solid darkblue;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    transition: all 0.5s;
}

.box-clients-table-users-button-edit button:hover {
    background-color: white;
    border: 1px solid darkblue;
    color: darkblue;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.3);
}

.box-clients-table-users-button-delete {
    width: 50%;
    padding: 0 5px;
}

.box-clients-table-users-button-delete button {
    width: 100%;
    padding: 5px;
    background-color: #dc3545;
    border: 1px solid #dc3545;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    transition: all 0.5s;
}

.box-clients-table-users-buttons svg {
    transition: all 0.2s ease-out;
}

.box-clients-table-users-buttons svg:hover {
    transform: scale(1.4);
    cursor: pointer;
}

.box-clients-table-users .spinner-border {
    color: #ff1a8d;
}

@media (max-width: 767px) {
    .clients-page .container .box.box-filtros {
        flex-direction: column;
        gap: 20px;
    }

    .box-clients-table-users-button-edit,
    .box-clients-table-users-button-delete {
        width: 100%;
    }

    .box-clients-table-title,
    .box-clients-table-users,
    .box-clients-table-users-gray {
        font-size: 14px;
    }

    .badge-danger,
    .badge-success {
        font-size: 12px;
    }
}

@media (max-width: 575px) {
    .clients-page .container {
        width: calc(100% - 20px);
        margin-left: 10px;
        margin-right: 10px;
    }

    .clients-page .container .row {
        padding: 15px;
    }

    .box-clients-table {
        padding: 16px 0;
    }

    .box-clients-table .row {
        padding: 0 !important;
    }

    .box-clients-table-title,
    .box-clients-table-users,
    .box-clients-table-users-gray {
        font-size: 12px;
    }

    .badge-danger,
    .badge-success {
        font-size: 12px;
    }
}