nav {
    width: 100%;
    height: 70px;
    background-color: black;
}

nav .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav .navbar-title {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-logo {
    height: 55px;
    margin-left: 50px;
}

.navbar-controls {
    position: relative;
    margin-right: 50px;
}

.navbar-icono {
    height: 40px;
    width: 40px;
    border: 1px solid white;
    cursor: pointer;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.5s;
}

.navbar-icono:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.navbar-icono.active {
    background-color: white;
}

.navbar-icono-user {
    height: 100%;
    filter: invert(1);
    transition: all 0.5s;
}

.navbar-icono-user.active {
    filter: none;
}

.navbar-expand {
    overflow: hidden;
    height: 0;
    will-change: height;
    width: 150px;
    border-radius: 0.5rem;
    border: none;
    transform: translate3d(0px, 34px, 0px);
    position: absolute;
    top: 10px;
    right: 0px;
    text-align: center;
    color: #fff;
    transition: all 0.2s;
    color: black;
    background-color: white;
    z-index: 1000;
}

.navbar-expand.active {
    height: auto;
    padding: 10px 0;
    box-shadow: 0 1px 4px 0 rgba(25,29,37,.101961);
}

.navbar-expand div {
    padding: 5px 0;
}

.navbar-expand div:hover {
    cursor: pointer;
    background-color: #f2f4f7;
}

@media (max-width: 480px) {
    .navbar-logo {
        margin-left: 30px;
    }

    .navbar-controls {
        margin-right: 30px;
    }
}